import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { Input, Form, Button } from '../Common/';
import { contactForm } from '../../utils/contactForm';
import useInput from '../../hooks/useInput';

const ContactMe = (props) => {
  const [name, setName, resetName] = useInput('');
  const [email, setEmail, resetEmail] = useInput('');
  const [message, setMessage, resetMessage] = useInput('');

  return ( 
    <section className="pt-32 md:pt-48 px-4 max-w-6xl mx-auto">
     <h1 className="text-3xl">
        <span className="font-bold">{<FormattedMessage id="emailInfo" />}</span> <a style={{ color: "#07f" }}
         target="_blank" rel="noopener noreferrer" href="mailto:olivielultimate@gmail.com">olivielultimate@gmail.com</a>
      </h1>
      <h1 className="text-3xl mt-4">{<FormattedMessage id="contactInfo" />}</h1>
      <Form className="text-3xl" 
        onSubmit={(e) => {
          contactForm(e, 'https://formspree.io/xbjydgda', 'POST', name, email, message);
          resetName();
          resetEmail();
          resetMessage();
        }}
      >
        <div className="mt-6">
          <p>{<FormattedMessage id="nameForm" />}:</p>
          <Input
            type="text"
            className="p-2 px-4 mt-4 rounded w-full"
            value={name}
            name="name"
            required="required"
            onChange={(e) => setName(e)}
          />
        </div>
        <div className="mt-6">
          <p>{<FormattedMessage id="emailForm" />}:</p>
          <Input
            type="email"
            className="p-2 px-4 mt-4 rounded w-full"
            value={email}
            name="email"
            required="required"
            onChange={(e) => setEmail(e)}
          />
        </div>
        <div className="my-6">
          <p>{<FormattedMessage id="messageForm" />}:</p>
          <textarea 
            className="w-full h-48 px-4 text-gray-800 rounded mt-4  outline-none border-black border-t border-b border-l border-r"
            value={message} 
            name="message"
            required="required"
            onChange={(e) => setMessage(e)} 
          />
        </div>
        <Button 
          className="bright-blue rounded-full py-2 w-40 px-8 text-white"
          text={<FormattedMessage id="sendForm" />}
        />
      </Form>
    </section>
  );
}
 
export default ContactMe;