import React, { useContext } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { Layout } from '../components/Common/';
import ContactMe from '../components/Contact/ContactMe';
import { SetLocationContext } from '../providers/LocationProvider';

const Contact = (props) => {
  const { formatMessage } = useIntl();
  const setLocation = useContext(SetLocationContext);
  setLocation(formatMessage({ id: 'navLink4' }));
  return ( 
    <Layout>
      <ContactMe />
    </Layout>
  );
}
 
export default Contact;