export function contactForm(e, url, method, ...data) {
  e.preventDefault();

  fetch('https://formspree.io/xbjydgda', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ...data }),
    })
    .catch((error) => {
      console.error('Error:', error);
  });
}